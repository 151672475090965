import React from 'react';
import Slider from 'rc-slider';
import Button from '../button';
import {mediaUrl} from '../base/functions';
import AudioPlayer from './audio_player/audio_player';

const clone = (obj) => {
  return JSON.parse(JSON.stringify(obj));
};

class AudioPlayerBlock extends React.Component {
  constructor(props) {
    super(props);

    this.players = {};
    this.seeking = false;
    this.seekingPlayState = false;
    this.seekingValue = 0;
    this.seekingDebounce = 0;

    this.state = {
      item: {},
      play: false,
      duration: 0,
      current: 0,
      seeking: false,
      playlist: []
    }
  }

  ref = player => {
    if (player) {
      this.players[player.id()] = player;
    } else {
      // Possible to get these during transition.
      //console.log('null player ref');
    }
  }

  componentDidMount(prevProps) {
    this.props.setNamedSignals([
      { key: 'audioPlayer', signal: 'active', data: true }
    ])
  }

  componentDidUpdate(prevProps) {
    const signals = this.props.namedSignals['audioPlayer']
    const item = (signals && signals.item) || {}
    const playlist = (signals && signals.playlist) || null;

    const newState = {};
    var changed = false;
    if (this.state.playlist !== playlist) {
      newState.playlist = playlist;
      changed = true;
    }
    if (this.state.item.id !== item.id) {
      newState.item = item;
      changed = true;
    }
    if (this.state.play !== signals.play) {
      newState.play = signals.play;
      changed = true;
    }
    if (changed) {
      this.setState(newState);
    }
  }

  onSliderChange = value => {
    this.seekingValue = value.toFixed(0);
    this.setState({ current: value });
  }

  onBeforeChange = () => {
    if(!this.seeking && !this.seekingDebounce) {
      this.seeking = true;
      this.seekingPlayState = this.state.play;
      this.setState({ play: false });
    }

    // rc-slider has a bug which causes multiple onBeforeChange events.
    // If they aren't debounced, it's possible for one to fire after
    // onAfterChange.
    this.seekingDebounce += 1;
    const self = this;
    setTimeout(function(){ self.seekingDebounce -= 1; }, 100);
  }

  onAfterChange = () => {
    if(this.seeking) {
      const item = this.state.item;
      if (item) {
        const player = this.players[item.id];
        player && player.seekTo(this.seekingValue);
      }

      this.setState({ play: this.seekingPlayState });
      this.seeking = false;
    }
  }

  next() {
    const state = this.state;
    const playlist = state.playlist;
    var next = {};

    if (!playlist) {
      return next;
    }

    if (!state.item) {
      next = (playlist[0]) || {};
    } else {
      playlist.forEach(function(item, i){
        if (item.id === state.item.id) {
          next = playlist[i+1] || {};
        }
      });
    }
    return next;
  }

  previous() {
    const state = this.state;
    const playlist = state.playlist;
    var previous = {};

    if (!playlist) {
      return previous;
    }

    if (!state.item) {
      previous = (playlist[0]) || {};
    } else {
      playlist.forEach(function(item, i){
        if (item.id === state.item.id) {
          previous = playlist[i-1] || {};
        }
      })
    }
    return previous;
  }

  setPrevious = id => {
    const player = this.players[id];
    if (player) {
      player.pause();
    }
    this.players[id] = null;

    var item = this.previous();
    if (item) {
      item = clone(item);

      // Calling setState with item helps reduce time to start the next track.
      this.setState({ item: item, current: 0 });

      // This is the source of truth about the current item and would eventually
      // cause this.state.item to be updated in componentDidUpdate.
      this.props.setNamedSignals([
        { key: 'audioPlayer', signal: 'item', data: item }
      ]);
    }
  }

  onPause = id => {
  }

  onEnded = id => {
    const player = this.players[id];
    if (player) {
      player.pause();
    }
    this.players[id] = null;

    var item = this.next();
    if (item) {
      item = clone(item);

      // Calling setState with item helps reduce time to start the next track.
      this.setState({ item: item, current: 0 });

      // This is the source of truth about the current item and would eventually
      // cause this.state.item to be updated in componentDidUpdate.
      this.props.setNamedSignals([
        { key: 'audioPlayer', signal: 'item', data: item }
      ]);
    }
  }

  onProgress = (id, state) => {
    if (!this.seeking) {
      this.setState({ current: state.playedSeconds });
    }
  }

  onDuration = (id, seconds) => {
    this.setState({ duration: seconds })
  }

  togglePlayer = (e, item) => {
    e.stopPropagation()

    var play = true;
    if (this.state.play) {
      play = false;
    }
    //this.setState({ play: play });
    this.props.setNamedSignals([
      { key: 'audioPlayer', signal: 'play', data: play }
    ]);
  }

  playButton(item, disabled) {
    const cls = disabled ? 'btn inline square disabled' : 'btn inline square';
    const play = this.state.play;
    return (
      <Button
        icon={play ? 'pause' : 'play'}
        label={play ? 'Pause' : 'Play'}
        handleClick={(e) => { this.togglePlayer(e,item) }}
        buttonClass={cls}
        disabled={disabled}
      />
    )
  }

  nextButton(item, disabled) {
    const cls = disabled ? 'btn inline square disabled' : 'btn inline square';
    return (
      <Button
        icon='skip-next'
        label='Next'
        handleClick={(e) => { this.onEnded(item.id) }}
        buttonClass={cls}
        disabled={disabled}
      />
    )
  }

  previousButton(item, disabled) {
    const cls = disabled ? 'btn inline square disabled' : 'btn inline square';
    return (
      <Button
        icon='skip-previous'
        label='Previous'
        handleClick={(e) => { this.setPrevious(item.id) }}
        buttonClass={cls}
        disabled={disabled}
      />
    )
  }

  playerUrl(item) {
    const attributes = item.attributes_hash || {}

    if (attributes.file) {
      return mediaUrl(
        [this.props.user.cdn, '/files/', item.data_type, '/', attributes.file]
      )
    }

    return null
  }

  timeString(s) {
    s = Math.round(s);
    const min = Math.floor(s/60);
    const sec = (s % 60).toString().padStart(2, '0');
    return `${min}:${sec}`;
  }

  marks() {
    const marks = {};
    const duration = this.duration();
    marks[0] = '0:00';
    marks[duration] = this.timeString(duration);
    return marks;
  }

  duration() {
    const item = this.state.item || {};
    const item_attributes = item.attributes_hash || {};
    const player = this.players[item.id];

    return parseInt(item_attributes.seconds) || (player && player.getDuration()) || this.state.duration;
  }

  render() {
    const props = this.props
    const block_attributes = props.block.instance.attributes_hash
    const item = this.state.item || {};
    const next = this.next();
    const item_attributes = item.attributes_hash || {};
    const coverUrl = item.coverUrl;
    const currentUrl = item && this.playerUrl(item);
    const nextUrl = next && this.playerUrl(next);
    const current = parseFloat(this.state.current.toFixed(2))

    return (
        <div className='audio-player'>
          <div className='image-frame center'
            style={coverUrl && { backgroundImage : `url(${coverUrl})` }} >
          </div>
          <div className='main' >
            <div className='name'>
              {item_attributes.name}
            </div>
            <div className='progress'>
              <Slider
                step={0.01}
                min={0}
                max={this.duration()}
                onChange={this.onSliderChange}
                onBeforeChange={this.onBeforeChange}
                onAfterChange={this.onAfterChange}
                value={current}
              />
            </div>
            <div className='control-frame'>
              <div className='elapsed-time'>{this.timeString(current)}</div>
              {this.previousButton(item, !this.previous().id)}
              {this.playButton(item, !currentUrl)}
              {this.nextButton(item, !nextUrl)}
              <div className='total-time'>{this.timeString(this.duration())}</div>
            </div>
          </div>
          { currentUrl &&
            <AudioPlayer
              ref={this.ref}
              key={item.id}
              id={item.id}
              url={currentUrl}
              playing={this.state.play}
              //onReady={() => console.log('onReady')}
              //onStart={() => console.log('onStart')}
              //onPlay={() => console.log('onPlay')}
              onPause={this.onPause}
              onEnded={this.onEnded}
              onProgress={this.onProgress}
              onDuration={this.onDuration}
            />
          }
          { nextUrl &&
            <AudioPlayer
              ref={this.ref}
              key={next.id}
              id={next.id}
              url={nextUrl}
              playing={false}
              //onReady={() => console.log('onReady')}
              //onStart={() => console.log('onStart')}
              //onPlay={() => console.log('onPlay')}
              onPause={this.onPause}
              onEnded={this.onEnded}
              onProgress={this.onProgress}
              onDuration={this.onDuration}
            />
          }
        </div>
    )
  }
}

export default AudioPlayerBlock;
