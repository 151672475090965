import React from 'react';
import ReactPlayer from 'react-player'

class AudioPlayer extends React.Component {
  constructor(props) {
    super(props);
  }

  ref = player => {
    this.player = player
  }

  id = () => this.props.id;

  onPause = () => {
    this.props.onPause(this.props.id);
  }

  onEnded = () => {
    this.props.onEnded(this.props.id);
  }

  onProgress = (state) => {
    this.props.onProgress(this.props.id, state);
  }

  onDuration = (seconds) => {
    this.props.onDuration(this.props.id, seconds);
  }

  seekTo = (seconds) => {
    this.player.seekTo(seconds, 'seconds');
  }

  pause = () => this.player.player.player.pause();

  getDuration = () => this.player.getDuration();

  render() {
    const props = this.props;

    return (
      <ReactPlayer
        ref={this.ref}
        url={props.url}
        playing={props.playing}
        progressInterval={300}
        config={{ file: { forceAudio: true }}}
        //onReady={() => console.log('onReady')}
        //onStart={() => console.log('onStart')}
        //onPlay={() => console.log('onPlay')}
        onPause={this.onPause}
        onEnded={this.onEnded}
        onProgress={this.onProgress}
        onDuration={this.onDuration}
        width='0px'
        height='0px'
      />
    )
  }
}

export default AudioPlayer;
